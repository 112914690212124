import axios from "axios";
import { ElMessage } from "element-plus";
import { useUserInfoStore } from '@/store/userInfo'

let $https = axios.create({
    baseURL: import.meta.env.VITE_SERVE,
    timeout: 5000, // 超时时间
});


// request实例添加请求拦截器
$https.interceptors.request.use((config) => {
    const userInfoStore = useUserInfoStore();
    if (userInfoStore.userInfo.token) {
        config.headers['x-access-token'] = userInfoStore.userInfo.token || '';
        config.headers['permission'] = userInfoStore.userInfo?.identityData?.permission || '';
    }
    // 返回配置对象, 公共参数
    return config
}, error => {
    return Promise.reject(error)
})

let elMsgFlag = true;

// request实例添加响应拦截器
$https.interceptors.response.use((response) => {
    // 成功回调
    if (response.data.code !== 0) {
        let msg = '';
        msg = response.data.msg;
        // 提示错误信息
        if (elMsgFlag) {
            ElMessage({
                type: 'error',
                message: msg
            })
        }

        // 判断token是否过期，如果roken过期，则取消后续的所有请求
        if (response.data.code === 30007 || response.data.code === 30012) {
            const userInfoStore = useUserInfoStore();
            elMsgFlag = false;
            userInfoStore.userLogout()
            return Promise.reject(response.data)
        }
    }
    return response.data
}, error => {
    // 失败回调
    let msg = '';
    let status = error?.response?.status;
    switch (status) {
        case 401:
            msg = 'token已过期~';
            break
        case 403:
            msg = '暂无访问权限';
            break
        case 404:
            msg = '网络请求地址404错误';
            break
        case 500:
            msg = '服务器出现问题';
            break
        default:
            msg = '请求超时，请稍后再试~';
            break
    }

    // 提示错误信息
    ElMessage({
        type: 'error',
        message: msg
    })

    return Promise.reject(error)
})

export default $https;