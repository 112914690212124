/**
 * 用户及权限API
 */
import $https from '@/utils/request';

/**
 * 创建API枚举
 * 统一管理API
 */
enum API {
    login = '/api/Login', // 登录
    logout = '/api/login/logout', // 退出登录
}

// 登录
export const login = (params: any) => $https.post(API.login, params)

// 退出登录
export const logout = () => $https.get(API.logout)
