export const loignRouters = [
	{
		name: 'Login',
		path: '/login',
		meta: {
			title: '登录',
			hideHeader: true
		},
		component: () => import('@/views/system/login/index.vue')
	}
]