<template>
	<div class="jc-header">
		<div class="jc-header__perch"></div>
		<div class="jc-header__fixed">
			<div class="jc-header__title-box">
				<el-image class="jc-header__logo" :src="$mConstDataConfig.systemLogo" />
				<h3 class="jc-header__title">{{ $mConstDataConfig.systemName }}</h3>
			</div>
			
			<el-menu mode="horizontal" :default-active="activePath" @select="onClickSelect">
				<el-menu-item :index="item.url" v-for="(item, index) in list" :default-active="item.url" :key="index">
					<el-image class="jc-header__icon" :src="item.iconPath" v-show="activePath !== item.url"></el-image>
					<el-image class="jc-header__icon" :src="item.selectPath" v-show="activePath === item.url"></el-image>
					<span class="hidden-md-and-down">{{ item.name || ''}}</span>
				</el-menu-item>
			</el-menu>
			
			<div class="jc-header__user">
				<el-input placeholder="北京市海滨区" size="large" suffix-icon="LocationFilled" readonly></el-input>

				<!-- 用户身份切换 -->
				<el-dropdown class="jc-header__user--cut" v-if="userInfo?.role?.identity.length > 1">
					<el-image class="jc-header__user--icon uy-pointer" :src="$mAssetsPath.user1"></el-image>
					<template #dropdown>
						<el-dropdown-menu>
							<el-dropdown-item v-for="(item, index) in userInfo?.role?.identity" :key="index" @click="onClickIdentity(item)">
								<span :class="item.permission === userInfo?.identityData?.permission? 'jc-header__user--cut-active': ''">{{ item.name }}</span>
							</el-dropdown-item>
						</el-dropdown-menu>
					</template>
				</el-dropdown>

				<!-- 消息 -->
				<el-icon size="24">
					<BellFilled />
				</el-icon>
				
				<!-- 个人中心 -->
				<el-dropdown>
					<el-image class="jc-header__user--icon uy-pointer" :src="userInfo.avatar || $mAssetsPath.user2"></el-image>
					<template #dropdown>
						<el-dropdown-menu>
							<!-- <el-dropdown-item>账户信息</el-dropdown-item> -->
							<!-- <el-dropdown-item divided @click="onClickLogout()">退出登录</el-dropdown-item> -->
							<el-dropdown-item @click="onClickLogout()">退出登录</el-dropdown-item>
						</el-dropdown-menu>
					</template>
				</el-dropdown>
			</div>

			<jcParticle :class="`uy-opacity-${scrollY < 10 ? scrollY : 10}`" />
		</div>
	</div>
</template>

<script setup lang="ts">
	import { reactive, ref, watch, onMounted } from 'vue'
	import { useRoute } from 'vue-router'
	import { useUserInfoStore  } from '@/store/userInfo'
	import { $mAssetsPath } from '@/config/assets.config'
	import { $mConstDataConfig } from '@/config/constData.config'
	import { logout } from '@/api/user'
	import { ElNotification } from 'element-plus'
	import jcParticle from '@/components/jc-particle/jc-particle.vue'
import router from '@/router'

	// 数据
	const route = useRoute();
	let activePath = ref();
	const list = reactive($mConstDataConfig.systemHeaderNav);
	const { userInfo, userLogin, userLogout } = useUserInfoStore();
	let scrollY = ref(0);

	// 生命周期
	onMounted(() => {
		window.addEventListener('scroll', handleScroll);
	})
	
	// 方法
	const onClickSelect = (e: any) => {
		activePath.value = e;
		router.replace(e);
	}

	const onClickIdentity = (e: any) => {
		userInfo['identityData']['permission'] = e.permission;
		userLogin(userInfo)
	}; // 身份切换
	
	const onClickLogout = async () => {
		try {
			await logout()
			userLogout()
		} catch {
			ElNotification({
				type: 'error',
				message: '退出登录失败，请联系开发人员~'
			})
			return
		}
	}; // 退出登录

	const handleScroll = () => {
		scrollY.value = window.scrollY;
	}
	
	// 监听事件
	watch(() => route, (val) => {
		activePath.value = val.path;
	}, { immediate: true, deep: true })
</script>

<style lang="scss" scoped>
	$height: 80px;
	$margin: 30px;
	
	.jc-header {
		&__perch {
			height: $height;
		}
		
		&__fixed {
			width: 100%;
			position: fixed;
			top: 0;
			left: 0;
			z-index: 999;
			display: flex;
			align-items: center;
			box-shadow: 0 1px 15px var(--uy-color-ffffff-10);
		}
		
		&__title-box {
			display: flex;
			align-items: center;
			margin: 0 $margin;
			flex-shrink: 0;
		}
		
		&__logo {
			width: 40px;
			height: 40px;
			box-shadow: 0 1px 15px var(--uy-color-ffffff-10);
			border-radius: 5px;
		}
		
		&__title {
			margin-left: 10px;
			position: relative;
			background-image: linear-gradient(145deg, var(--uy-color-ffffff), var(--uy-color-ffffff), var(--uy-color-ffffff), var(--uy-color-000000));
			background-clip: text;
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
			font-weight: bold;
		}
		
		&__icon {
			width: 35px;
			height: 35px;
			margin-right: 10px;
		}
		
		&__user {
			display: flex;
			align-items: center;
			gap: 20px;
			margin: 0 $margin;
			
			&--cut-active {
				color: var(--uy-color-1E90FF);
			}
			
			&--icon {
				width: 20px;
				height: 20px;
			}
		}
	}
	
	.el-menu {
		background-color: transparent;
		flex: 1;
	}
	
	.el-menu-item {
	  font-size: 14px;
	  margin-right: 15px;
	  transition: all 0.3s ease;
	  color: var(--uy-color-ffffff) !important;
	}
	
	.el-menu-item * {
		vertical-align: initial;
		display: flex;
		align-items: center;
	}
	
	.el-menu-item:hover {
		background-color: transparent;
	}

	.el-menu-item:focus {
	  color: #409EFF;
	}
	
	.el-menu--horizontal.el-menu, .el-menu--horizontal>.el-menu-item {
		border: none;
		height: $height;
	}
	
	.el-menu-item.is-active {
		border: none !important;
	}
	
	:deep(.el-image__placeholder) {
		background: transparent;
	}
	
	:deep(.el-input) {
		--el-input-border-color: var(--uy-color-ffffff-20);
		--el-input-placeholder-color: var(--uy-color-A0AEC0);
		--el-input-icon-color: var(--uy-color-ffffff);
		--el-input-hover-border-color: var(--uy-color-ffffff-50);
		--el-input-focus-border-color: var(--uy-color-ffffff-20);
		min-width: 200px;
	}
	
	:deep(.el-input__wrapper) {
		background-color: transparent;
		border-radius: 30px;
	}
</style>