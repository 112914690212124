<template>
	<div class="sky">
		<div class="meteor"></div>
		<div class="meteor"></div>
		<div class="meteor"></div>
		<div class="meteor"></div>
		<div class="meteor"></div>
		<div class="meteor"></div>
		<div class="meteor"></div>
		<div class="meteor"></div>
	</div>
</template>

<script setup lang="ts">
</script>

<style lang="scss" scoped>
	.sky {
		position: fixed;
		width: 100%;
		height: 100vh;
		left: 0;
		top: 0;
		z-index: -1;
	}
	
	.meteor {
		position: absolute;
		width: 2px;
		/* 流星的宽度 */
		height: 100px;
		/* 流星的高度 */
		background: linear-gradient(to bottom, white, rgba(255, 255, 255, 0));
		/* 流星颜色 */
		opacity: 0;
		border-radius: 20%;
		/* 流星圆角 */
		animation: fall 2s linear infinite;
		/* 动画效果 */
	}

	/* 定义流星的落下动画 */
	@keyframes fall {
		0% {
			transform: translate(-100vh, 100vh) rotate(45deg);
			/* 起始位置 */
			opacity: 0;
			/* 透明度从1开始 */
		}

		50% {
			opacity: 0.5;
			/* 中间透明度变为0.5 */
		}

		100% {
			transform: translate(0, 0) rotate(45deg);
			/* 向左下方落下 */
			opacity: 0;
			/* 最后完全透明 */
		}
	}

	/* 为每个流星设置不同的动画延迟和位置 */
	.meteor:nth-child(1) {
		top: 0%;
		left: 30%;
		animation-delay: 0s;
	}

	.meteor:nth-child(2) {
		top: 10%;
		left: 40%;
		animation-delay: 2.5s;
	}

	.meteor:nth-child(3) {
		top: 15%;
		left: 50%;
		animation-delay: 1s;
	}

	.meteor:nth-child(4) {
		top: 0%;
		left: 60%;
		animation-delay: 1.5s;
	}

	.meteor:nth-child(5) {
		top: 25%;
		left: 70%;
		animation-delay: 2s;
	}

	.meteor:nth-child(6) {
		top: 5%;
		left: 80%;
		animation-delay: 2.5s;
	}
	
	.meteor:nth-child(7) {
		top: 15%;
		left: 95%;
		animation-delay: 3s;
	}
	
	.meteor:nth-child(8) {
		top: 0%;
		left: 105%;
		animation-delay: 3.5s;
	}
</style>