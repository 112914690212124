export const homeRouters = [
	{ // 首页重定向
		path: '/',
		redirect: '/home'
	},
	{ // 首页
		name: 'Home',
		path: '/home',
		meta: {
			title: '首页'
		},
		component: () => import('@/views/system/home/index.vue')
	},
	{ // 房源管理（列表）
		name: 'Housing',
		path: '/housing',
		meta: {
			title: '房源管理',
			keepAlive: true
		},
		component: () => import('@/views/housing/index.vue')
	},
	{ // 房源管理（录入）
		name: 'HousingAdd',
		path: '/housingAdd',
		meta: {
			title: '房源录入'
		},
		component: () => import('@/views/housing/add/index.vue')
	},
	{ // 房源管理（详情）
		name: 'HousingDetails',
		path: '/housingDetails',
		meta: {
			title: '房源管理'
		},
		component: () => import('@/views/housing/details/index.vue')
	},
	{
		name: 'HousingHeadline',
		path: '/housingHeadline',
		meta: {
			title: '接客标题'
		},
		component: () => import('@/views/housing/headline/index.vue')
	},
	{ // 客源管理（列表）
		name: 'Passenger',
		path: '/passenger',
		meta: {
			title: '客源管理',
			keepAlive: true
		},
		component: () => import('@/views/passenger/index.vue')
	},
	{ // 客源管理（录入）
        name: 'PassengerAdd',
        path: '/passengerAdd',
        meta: {
            title: '客源录入'
        },
        component: () => import('@/views/passenger/add/index.vue')
    },
	{ // 客源管理（详情）
        name: 'PassengerDetails',
        path: '/passengerDetails',
        meta: {
            title: '客源管理'
        },
        component: () => import('@/views/passenger/details/index.vue')
    }, 
	{
		name: 'Premises',
		path: '/premises',
		meta: {
			title: '楼盘字典'
		},
		component: () => import('@/views/premises/index.vue')
	},
	{
		name: 'Contract',
		path: '/contract',
		meta: {
			title: '合同管理'
		},
		component: () => import('@/views/contract/index.vue')
	},
	{
		name: 'Principal',
		path: '/principal',
		meta: {
			title: '负责人管理'
		},
		component: () => import('@/views/principal/index.vue')
	},
	{
		name: 'Area',
		path: '/area',
		meta: {
			title: '片区统计'
		},
		component: () => import('@/views/area/index.vue')
	},
	{ // 钥匙管理（列表）
        name: 'Keyholder',
        path: '/keyholder',
        meta: {
            title: '钥匙管理'
        },
        component: () => import('@/views/keyholder/index.vue')
    }
]