import { defineStore } from "pinia";
import router from '@/router'

/**
 * 用户相关
 * @example 使用示例 import { useUserInfoStore } from '@/store/userInfo'
 * const userInfoStore = useUserInfoStore()
 * 存储 userInfoStore.userLogin(object)
 * 读取 userInfoStore.authLogin
 */

export const useUserInfoStore = defineStore('userInfo', {
	// 状态
	state() {
		return {
			authLogin: JSON.parse(localStorage.getItem('authLogin') as any) || false,
			userInfo: JSON.parse(localStorage.getItem('userInfo')  as any) || {}
		}
	},
	actions: {
		// 登录
		userLogin(val: object) {
			this.authLogin = true;
			this.userInfo = val;
			localStorage.setItem('authLogin', JSON.stringify(true))
			localStorage.setItem('userInfo', JSON.stringify(this.userInfo))
		},
		// 退出登录
		userLogout() {
			this.authLogin = false;
			this.userInfo = {};
			localStorage.removeItem('userInfo')
			localStorage.removeItem('authLogin')
			localStorage.clear()
			router.replace({ name: 'Login' })
		}
	}
})