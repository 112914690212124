/**
 * 图片静态资源表，所有图片资源路径在这统一管理，不应该写死在页面中
 * 页面使用
 * import { $mAssetsPath } from '@/config/assets.config.ts'
 * $mAssetsPath.xxx
 */

export const $mAssetsPath = {
	logo: new URL('@/assets/logo.svg', import.meta.url).href, // 应用LOGO

	// 图标相关
	icon1: new URL('@/assets/img/icon1.png', import.meta.url).href, // 关注（默认）
    icon2: new URL('@/assets/img/icon2.png', import.meta.url).href, // 关注（高亮）
	icon3: new URL('@/assets/img/icon3.png', import.meta.url).href, // 拉黑
    icon4: new URL('@/assets/img/icon4.png', import.meta.url).href, // 编辑
    icon5: new URL('@/assets/img/icon5.png', import.meta.url).href, // 锁
    icon6: new URL('@/assets/img/icon6.png', import.meta.url).href, // 锤子
    icon7: new URL('@/assets/img/icon7.png', import.meta.url).href, // 删除
	icon8: new URL('@/assets/img/icon8.png', import.meta.url).href, // 借还（标识）
	icon9: new URL('@/assets/img/icon9.png', import.meta.url).href, // 任务（图标）
	icon10: new URL('@/assets/img/icon10.png', import.meta.url).href, // 录入（房源）
	icon11: new URL('@/assets/img/icon11.png', import.meta.url).href, // 地图（图标）
	icon12: new URL('@/assets/img/icon12.png', import.meta.url).href, // 钥匙（图标）
	icon13: new URL('@/assets/img/icon13.png', import.meta.url).href, // 录入（客源）
	icon14: new URL('@/assets/img/icon14.png', import.meta.url).href, // 成交（图标）
	icon15: new URL('@/assets/img/icon15.png', import.meta.url).href, // 上报已租（图标）

	// 用户相关
	user1: new URL('@/assets/img/user1.png', import.meta.url).href, // 身份切换
	user2: new URL('@/assets/img/user2.png', import.meta.url).href, // 头像默认

	// 3D
	d31: new URL('@/assets/img/d31.png', import.meta.url).href, // 3D模型1
	d32: new URL('@/assets/img/d32.png', import.meta.url).href, // 3D模型2
	
	// tabBar
	home: new URL('@/assets/tabBar/home.png', import.meta.url).href, // 首页
	home1: new URL('@/assets/tabBar/home1.png', import.meta.url).href, // 首页-选中
	housing: new URL('@/assets/tabBar/housing.png', import.meta.url).href, // 房源管理
	housing1: new URL('@/assets/tabBar/housing1.png', import.meta.url).href, // 房源管理-选中
	passenger: new URL('@/assets/tabBar/passenger.png', import.meta.url).href, // 客源管理
	passenger1: new URL('@/assets/tabBar/passenger1.png', import.meta.url).href, // 客源管理-选中
	premises: new URL('@/assets/tabBar/premises.png', import.meta.url).href, // 楼盘字典
	premises1: new URL('@/assets/tabBar/premises1.png', import.meta.url).href, // 楼盘字典-选中
	contract: new URL('@/assets/tabBar/contract.png', import.meta.url).href, // 楼盘字典
	contract1: new URL('@/assets/tabBar/contract1.png', import.meta.url).href, // 楼盘字典-选中
	principal: new URL('@/assets/tabBar/principal.png', import.meta.url).href, // 负责人管理
	principal1: new URL('@/assets/tabBar/principal1.png', import.meta.url).href, // 负责人管理-选中
	area: new URL('@/assets/tabBar/area.png', import.meta.url).href, // 片区统计
	area1: new URL('@/assets/tabBar/area1.png', import.meta.url).href, // 片区统计-选中
}