import nProgress from 'nprogress'
import 'nprogress/nprogress.css';
import { createRouter, createWebHistory } from 'vue-router'
import { routerArray } from './routers'
import { useUserInfoStore } from '@/store/userInfo'

const router = createRouter({
	history: createWebHistory(import.meta.env.BASE_URL),
	routes: routerArray,
	strict: true,
	scrollBehavior: () => ({ left: 0, top: 0 })
})

// ----------------------- 路由加载前 -----------------------
router.beforeEach(async (to, from, next) => {
	// 进度条开启
	nProgress.start();
	
	const { authLogin } = useUserInfoStore();
	const publicPages = ['/login', '/404'];

	// 公共页面, 任何时候都可以跳转
	if(publicPages.includes(to.path)) {
		next()
		return
	}
	
	// 验证登录
	if(!authLogin) {
		localStorage.clear();
		next({ name: 'Login' })
		return
	}
	
	// 首页（ 需要登录 ，但不需要验证权限）
	if(to.path === '/home') {
		next()
		return
	}
	
	next()
})

// ----------------------- 路由加载后 -----------------------
router.afterEach(() => {
    nProgress.done();
});

export default router