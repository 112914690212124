<template>
	<div id="app">
		<jcHeader v-if="!hideHeader" />
		
		<router-view v-slot="{ Component }">
			<KeepAlive :include="['Housing','Passenger']">
				<component :is="Component" />
			</KeepAlive>
		</router-view>
	</div>

	<div class="uy-fixed uy-r-10 uy-t-100 uy-w-70 uy-b-r-10 uy-flex uy-flex-row-center uy-flex-column-center" @click="onClickBack" v-if="!hideHeader">
		<el-image class="uy-w-p-100 uy-h-p-100" :src="$mAssetsPath.d31"></el-image>
	</div>
	
	<jcParticle />
	<jcMeteor />
</template>

<script setup lang="ts">
	import { ref, watch } from 'vue'
	import { useRoute } from 'vue-router';
	import { debounce } from 'lodash';
	import { $mAssetsPath } from '@/config/assets.config';
	import router from '@/router';
	
	// 数据
	const route = useRoute()
	let hideHeader = ref<boolean>(true);
	let keepAlive = ref<boolean>(false);

	// 方法
	const onClickBack = debounce(() => {
		router.back()
	}, 500)
	
	// 监听事件
	watch(route, (val) => {
		hideHeader.value = Boolean(val.meta.hideHeader);
		keepAlive.value = Boolean(val.meta.keepAlive);
	}, { immediate: true })
</script>

<style lang="scss" scoped>
// el-image样式
:deep(.el-image__placeholder) {
	background: transparent;
}

// 表格
:deep(.el-table) {
	--el-table-bg-color: transparent;
	--el-table-tr-bg-color: transparent;
	--el-table-header-bg-color: transparent;
	--el-table-border-color: var(--uy-color-ffffff-20);
	--el-table-row-hover-bg-color: var(--uy-color-ffffff-10);
	color: #ffffff;
}

:deep(.current-row) {
	--el-table-current-row-bg-color: var(--uy-color-ffffff-10);
}

:deep(.el-table__cell) {
	padding: 10px 0 !important;
}

:deep(.cell) {
	padding: 0 5px !important;
}

:deep(.el-table__body td .cell:empty::before) {
	content: '---';
}

// el-select样式
:deep(.el-select--large .el-select__wrapper) {
	min-height: 0 !important;
	padding: 4px 12px;
}

// 分页样式
:deep(.el-pagination) {
	--el-pagination-button-bg-color: transparent;
	--el-pagination-button-color: rgba(255, 255, 255, 0.65);
}

:deep(.el-pager li) {
	border: 1px solid rgba(255, 255, 255, 0.20);
	color: rgba(255, 255, 255, 0.65);
}

:deep(.el-pagination.is-background .btn-prev:disabled),
:deep(.el-pagination.is-background .btn-prev),
:deep(.el-pagination.is-background .btn-next) {
	border: 1px solid rgba(255, 255, 255, 0.20);
	color: rgba(255, 255, 255, 0.65);
	background: transparent !important;
}

:deep(.el-pagination.is-background .el-pager li.is-active) {
	background: var(--uy-color-1E90FF) !important;
}

:deep(.el-pagination__editor .el-input__wrapper) {
	background-color: transparent;
	box-shadow: 0 0 0 1px var(--uy-color-ffffff-20);

	.el-input__inner {
		color: var(--uy-color-ffffff);
	}
}

:deep(.el-pagination__jump) {
	color: var(--uy-color-ffffff);
}
</style>