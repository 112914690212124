import './assets/css/main.scss'

import { createApp } from 'vue'
import { createPinia } from 'pinia'

import App from './App.vue'
import router from './router'

// 引入全局element-plus
import ElementPlus from 'element-plus'
import * as ElementPlusIconVue from '@element-plus/icons-vue'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import 'element-plus/dist/index.css'
import 'element-plus/theme-chalk/display.css'
import VueAMap, { initAMapApiLoader } from '@vuemap/vue-amap';
import '@vuemap/vue-amap/dist/style.css'

const app = createApp(App)

app.use(createPinia())
app.use(router)

// 挂载element-plus
app.use(ElementPlus, {
	locale: zhCn,
})
// 挂载element-plus的icon
for (const [key, component] of Object.entries(ElementPlusIconVue)) {
	app.component(key, component)
}
// 挂载vue-amap
app.use(VueAMap)
initAMapApiLoader({
	key: "24d32a6707a6c05c1d59b5d47f357d19", // 高德地图key
})

app.mount('#app')
