import { $mAssetsPath } from "./assets.config"

export const $mConstDataConfig = {
    // 应用名称
    systemName: '房知音',

    // 应用描述
    systemDesc: 'FANG ZHIYIN',

    // 应用LOGO
    systemLogo: $mAssetsPath.logo,

	pageSize: 10, // 每页显示的数量
	
	// 应用Header导航
	systemHeaderNav: [
		{ name: '首页', iconPath: $mAssetsPath.home, selectPath: $mAssetsPath.home1, url: '/home' },
		{ name: '房源管理', iconPath: $mAssetsPath.housing, selectPath: $mAssetsPath.housing1, url: '/housing' },
		{ name: '客源管理', iconPath: $mAssetsPath.passenger, selectPath: $mAssetsPath.passenger1, url: '/passenger' },
		{ name: '楼盘字典', iconPath: $mAssetsPath.premises, selectPath: $mAssetsPath.premises1, url: '/premises' },
		{ name: '合同管理', iconPath: $mAssetsPath.contract, selectPath: $mAssetsPath.contract1, url: '/contract' },
		{ name: '负责人管理', iconPath: $mAssetsPath.principal, selectPath: $mAssetsPath.principal1, url: '/principal' },
		{ name: '片区统计', iconPath: $mAssetsPath.area, selectPath: $mAssetsPath.area1, url: '/area' }
	],

	// 房源管理
	housingTableCopywriter: [
		{ key: 'coverPath', title: '封面图', minWidth: 110 },
		{ key: 'villageName', title: '小区名称', minWidth: 100 },
		{ key: 'fyStatusName', title: '房源状态', minWidth: 100 },
		{ key: 'fyGradeName', title: '等级', minWidth: 60, sortable: true },
		{ key: 'buildingNumber', title: '房源楼栋', minWidth: 100 },
		{ key: 'apartmentType', title: '房源房型', minWidth: 100 },
		{ key: 'fyPropertion', title: '面积', minWidth: 70, sortable: true },
		{ key: 'fyPrice', title: '价格', minWidth: 80, sortable: true },
		{ key: 'fyFkfsName', title: '付款方式', minWidth: 100 },
		{ key: 'isPublishName', title: '发布', minWidth: 80 },
		{ key: 'fyZxcdName', title: '装修', minWidth: 100 },
		{ key: 'fyCwName', title: '车位', minWidth: 100 },
		{ key: 'modifyTime', title: '调价记录', minWidth: 100, sortable: true },
		{ key: 'groundTime', title: '上架日期', minWidth: 100, sortable: true },
		{ key: 'verificationStateName', title: '核验', minWidth: 60 },
		{ key: 'fyBzDay', title: '布置', minWidth: 55 },
		{ key: 'fyCf', title: '新发', minWidth: 55 },
		{ key: 'fyXf', title: '重发', minWidth: 55 },
		{ key: 'fyGjTime', title: '跟进日期', minWidth: 100, sortable: true },
		{ key: 'mainly_popularize_set', title: '主推设置', minWidth: 100, align: 'right', fixed: 'right' }
	], // 列表（table文案头部）

	housingTableMarket: [
		{ key: 'coverPath', title: '封面图', minWidth: 110 },
		{ key: 'villageName', title: '小区名称', minWidth: 120 },
		{ key: 'fyStatusName', title: '房源状态', minWidth: 120 },
		{ key: 'fyGradeName', title: '等级', minWidth: 60, sortable: true },
		{ key: 'buildingNumber', title: '房源楼栋', minWidth: 120 },
		{ key: 'apartmentType', title: '房源房型', minWidth: 120 },
		{ key: 'fyPropertion', title: '面积', minWidth: 80, sortable: true },
		{ key: 'fyPrice', title: '价格', minWidth: 100, sortable: true },
		{ key: 'fyCwName', title: '车位', minWidth: 100 },
		{ key: 'modifyTime', title: '调价记录', minWidth: 120, sortable: true },
		{ key: 'fyFkfsName', title: '付款方式', minWidth: 100 },
		{ key: 'fyZxcdName', title: '装修程度', minWidth: 100, sortable: true },
		{ key: 'isPublishName', title: '发布', minWidth: 80 },
		{ key: 'groundTime', title: '上架日期', minWidth: 100, sortable: true },
		{ key: 'fyGjTime', title: '跟进日期', minWidth: 100, sortable: true },
		{ key: 'mainly_popularize_set', title: '主推设置', minWidth: 100, sortable: true, align: 'right', fixed: 'right' }
	], // 列表（table销售头部）

	// 客源管理
	passengerTableCopywriter: [
		// { key: 'kyName', title: '姓名', minWidth: 200 },
		{ key: 'titleName', title: '接客标题', minWidth: 90},
        { key: 'intentionVillageName', title: '意向小区', minWidth: 100 },
		{ key: 'intentionPropertion', title: '意向面积', minWidth: 90, sortable: true },
		{ key: 'intentionPrice', title: '预算价格', minWidth: 100, sortable: true },
		{ key: 'apartmentType', title: '房型', minWidth: 100 },
        { key: 'createTime', title: '录入时间', minWidth: 100, sortable: true, align: 'right' },
	], // 列表（table文案头部）

	passengerTableMarket: [
		{ key: 'kyName', title: '姓名', minWidth: 120 },
		{ key: 'mustDeal', title: '必成交', minWidth: 110, sortable: true },
        { key: 'kyStatusName', title: '客源状态', minWidth: 110 },
        { key: 'kyGradeName', title: '客源等级', minWidth: 110, sortable: true },
        { key: 'sex', title: '性别', minWidth: 70, sortable: true },
        { key: 'intentionVillageName', title: '意向小区', minWidth: 110 },
        { key: 'kyFkfsName', title: '付款方式', minWidth: 110 },
        { key: 'intentionZxcdName', title: '意向装修', minWidth: 110 },
        { key: 'intentionPrice', title: '预算价格', minWidth: 110, sortable: true },
        { key: 'groundTime', title: '上架日期', minWidth: 90, sortable: true },
        { key: 'tackLook', title: '7天带看', minWidth: 90, sortable: true },
        { key: 'intentionGjTime', title: '跟进日期', minWidth: 110, sortable: true },
        { key: 'kyLyName', title: '客户来源', minWidth: 90 },
        { key: 'createTime', title: '录入时间', minWidth: 90, sortable: true },
        { key: 'mainly_popularize_set', title: '必成交设置', minWidth: 130, sortable: true, align: 'right', fixed: 'right' }
	], // 列表（table销售头部）

	// 钥匙借还
	keyholderTable: [
		{ width: 40 },
		{ key: 'coverPath', title: '封面图', width: 100 },
		{ key: 'villageName', title: '小区名称' },
		{ key: 'fyStatusName', title: '房源状态', width: 150 },
		{ key: 'fyGradeName', title: '房源等级', width: 150, sortable: true },
		{ key: 'fyLdh', title: '楼栋号' },
		{ key: 'apartmentType', title: '房型', width: 150 },
		{ key: 'fyPropertion', title: '面积', width: 150, sortable: true },
		{ key: 'fyPrice', title: '价格', width: 150, sortable: true },
		{ key: 'storeName', title: '钥匙门店', width: 150 },
		{ key: 'keyTypeName', title: '钥匙类型', width: 150 },
		{ key: 'mainly_popularize_set', title: '钥匙借还操作', width: 200, sortable: true, align: 'right' }
	], // 列表table头部

	// 全局导航
	fixedNav: [
		{ name: '我的任务', icon: $mAssetsPath.icon9, show: ['housing', 'passenger'],  },
		{ name: '上报已租', icon: $mAssetsPath.icon9, click: true, show: ['housingDetails'] },
		{ name: '房源录入', icon: $mAssetsPath.icon10, url: 'HousingAdd', show: ['housing', 'housingDetails'] },
		{ name: '地图找房', icon: $mAssetsPath.icon11, show: ['housing'] },
		{ name: '客源录入', icon: $mAssetsPath.icon13, url: 'PassengerAdd', show: ['passenger', 'passengerDetails'] },
		{ name: '钥匙管理', icon: $mAssetsPath.icon12, url: 'Keyholder', show: ['housing', 'passenger', 'passengerDetails'] },
		{ name: '借还钥匙', icon: $mAssetsPath.icon12, url: 'Keyholder', show: ['housingDetails'] },
	], // 固定导航
}